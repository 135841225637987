import React, {Component} from "react"
import { Link } from "react-router-dom";

class PrivacyPolicyView extends Component {
    render() {
        const backButton = ((window.localStorage.getItem('consent') || false) ? null : 
            <div>
                <div className="" style={{margin: "2rem"}}>
                    <Link to={"/aboutapp"} className="btn btn-primary btn-rounded" style={{color: 'white'}}>&lt; terug</Link>
                </div>
            </div>
        );

        return (
            <div className="content container">
                <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{background: "url('/images/aboutapp/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                        </div>
                    </div>
                    {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a> */}
                </div>

                {backButton}
                <h2>Privacyverklaring Joris Ijs</h2>
                <h5>laatste wijziging 3 oktober 2022</h5><br />

<p><h3>Algemeen</h3></p>
<p>Deze Privacyverklaring regelt de verwerking van uw persoonsgegevens als een onderdeel
van het gebruik van onze website (https://www.jorisijs.be/) en de diensten die daarmee
verband houden. Deze verwerkingen worden steeds uitgevoerd door Joris Ijs als
verwerkingsverantwoordelijke, gevestigd te 2340 Beerse, Rouwbergskens 4 met
ondernemingsnummer 0473.753.839.</p>

<p>Het beschermen van persoonsgegevens van klanten en bezoekers is erg belangrijk voor Joris
Ijs. Daarom stellen we alles in het werk op deze gegevensbescherming te waarborgen en
steeds conform de General Data Protection Regulation (GDPR) te handelen wanneer wij uw
persoonsgegevens verwerken. In hoofde van de persoonsgegevens die wij verwerken op
onze website (https://www.jorisijs.be/) zal Joris Ijs steeds optreden als
verwerkingsverantwoordelijke.</p>

<p>Meer concreet zal Joris Ijs de persoonsgegeven steeds rechtmatig behandelen voor
welbepaalde doeleinden. Wij zullen het nodige doen om nooit meer persoonsgegevens te
vragen dan noodzakelijk voor het doel of om deze persoonsgegevens langer dan nodig te
bewaren. Tot slot neemt Joris Ijs de passende technische en organistatorische maatregelen
die een passende beveiliging waarborgen zodat de persoonsgegevens worden beveiligd
tegen ongeoorloofde of onrechtmatige verwerking en tegen onopzettelijk verlies,
vernietiging of beschadiging.
</p>

<p>Voor meer informatie kan u zich steeds wenden tot info@jorisijs.be.</p>

<p>Deze Privacyverklaring was laatst bijgewerkt op 03/10/2022. Joris Ijs behoudt zich het recht
om deze Privacyverklaring naar eigen goeddunken aan te passen. Het zal de bezoekers hier
ook steeds van op de hoogte brengen.</p>

<p><h3>Persoonsgegevens</h3></p>
<p>De verwerking van persoonsgegevens definiëren wij als een bewerking of een geheel van
bewerkingen met betrekking tot persoonsgegevens of een geheel van persoonsgegevens,
al dan niet uitgevoerd via geautomatiseerde procedés, zoals het verzamelen, vastleggen,
ordenen, structureren, opslaan, bijwerken of wijzigen, opvragen, raadplegen, gebruiken,
verstrekken door middel van doorzending, verspreiden of op andere wijze ter beschikking
stellen, aligneren of combineren, afschermen, wissen of vernietigen van gegevens.</p>

<p>Conform de huidige regelgeving verstaan we onder persoonsgegevens alle informatie over
een geïdentificeerde of identificeerbare natuurlijke persoon (“de betrokkene”); als
3
identificeerbaar wordt beschouwd een natuurlijke persoon die direct of indirect kan worden
geïdentificeerd, met name aan de hand van een identificator zoals een naam, een
identificatienummer, locatiegegevens, een online identificator of van een of meer
elementen die kenmerkend zijn voor de fysieke, fysiologische, genetische, psychische,
economische, culturele of sociale identiteit van die natuurlijke persoon.</p>

<p>Voorbeelden van persoonsgegevens zijn naam, adres of e-mailadres, maar tevens valt hier
uw IP-adres onder</p>

<p><h4>Waarom verwerken wij uw persoonsgegevens?</h4></p>
<p>Joris Ijs zal uw persoonsgegevens verwerken voor de volgende doeleinden:</p>

<table>
    <tr>
        <th>Verwerkingsdoeleinden</th>
        <th>Rechtsgrond</th>
    </tr>

    <tr>
        <td>Verwerken van uw vraag via het
contactformulier, per mail, telefonisch of via
social media</td>
        <td>Gerechtvaardigd belang</td>
    </tr>

    <tr>
        <td>Direct marketing doeleinden om u te kunnen
voorzien van individuele communicatie,
promoties, aanbiedingen en andere
advertenties van ons of onze geselecteerde
partners.</td>
        <td>Gerechtvaardigd belang</td>
    </tr>

    <tr>
        <td>U informeren met behulp van periodieke
nieuwsbrie</td>
        <td>Toestemming</td>
    </tr>

    <tr>
        <td>Profilering door middel van een cookie die uw
onlinegedrag en voorkeuren analyseren. Op die
manier houden wij u op de hoogte van
interessante activiteiten bij Joris Ijs die u
interesseren
</td>
        <td>Toestemming</td>
    </tr>

    <tr>
        <td>Klantenbeheer</td>
        <td>Uitvoering van een
overeenkomst</td>
    </tr>

    <tr>
        <td>Interne doeleinden zoals audits,
gegevensanalyse en onderzoek om de
producten, diensten en communicatie te
verbeteren</td>
        <td>Gerechtvaardigd belang</td>
    </tr>
</table>

<p><h4>Aan wie geven wij uw persoonsgegevens door?</h4></p>
<p>Wij zullen uw persoonsgegevens niet op een identificeerbare manier bezorgen aan derden,
tenzij dit wettelijk vereist is of tenzij u hiervoor uw uitdrukkelijke toestemming hebt
gegeven</p>

<p>Joris Ijs weerhoudt zich het recht om de persoonsgegevens te anonimiseren en bijgevolg
zo over te maken aan andere organisaties die deze gegevens kunnen gebruiken om
producten en diensten te verbeteren en de marketing, presentatie en verkoop van
producten en diensten op maat te organiseren. 
</p>

<p>Joris Ijs zal het nodige doen om de persoonsgegevens louter binnen de Europese
Economische Ruimte te verwerken.</p>

<p><h4>Hoe lang bewaren wij uw persoonsgegevens?</h4></p>
<p>Uw persoonsgegevens worden alleen verwerkt zolang als dit nodig is voor de doeleinden
omschreven in deze Privacyverklaring of als u uw toestemming voor de verwerking intrekt.
In dat geval zullen wij het nodige doen om de verwerking van uw persoonsgegevens zo
snel mogelijk stop te zetten.</p>

<p><h4>Welke rechten kan u als betrokkene uitoefenen?</h4></p>
<p>U heeft als betrokkene het recht op inzage tot alle persoonsgegevens die Joris Ijs over u
verwerkt. U heeft als betrokkene het recht op rectificatie te vragen over persoonsgegevens
die onjuist of onnauwkeurig zijn</p>
<p>Wanneer de verwerking van uw persoonsgegevens plaatsvindt op basis van toestemming
bent u steeds in de mogelijkheid om deze toestemming in te trekken. Dit kan door contact
op te nemen met ons Data Protection Team op info@jorisijs.be.</p>
<p>U heeft als betrokkene het recht om te vragen dat uw persoonsgegevens worden
verwijderd als deze niet langer nodig zijn in het licht van de doeleinden van deze
Privacyverklaring of als u uw toestemming voor de verwerking intrekt. Dit recht kan echter
niet worden uitgeoefend als Joris Ijs door een andere wettelijke bepaling gehouden is deze
persoonsgegevens verder te verwerken.</p>
<p>Als u de bovenstaande rechten van de betrokkene wilt uitoefenen of als u verder nog
vragen zou hebben kan u steeds contact opnemen met ons op info@jorisijs.be. Wanneer u
uw rechten wenst uit te oefenen zal u zich wel steeds ten gepaste wijze moeten
identificeren. Dit gaat als u uw verzoek bijvoorbeeld stuurt vanaf het e-mailadres dat reeds
bij ons bekend is.</p>
<p>Mocht u niet tevreden zijn met de behandeling van uw verzoek kan u steeds klacht in
dienen bij de bevoegde gegevensbeschermingsautoriteit, zijnde de
Gegevensbeschermingsautoriteit in België. Voor meer informatie kan u steeds terecht op
www.privacycommission.be.</p>

<p><h3>Cookies</h3></p>
<p>Onze website (https://www.jorisijs.be/) maakt gebruik van cookies. Cookies zijn kleine
informatiebestanden bewaard worden op de harde schijf van uw computer. Deze informatie
doet uw bezoek aan de site sneller en efficiënter verlopen. Indien u het gebruik van cookies
uitschakelt in uw browser, kunnen wij u geen correcte werking van de website garanderen.
De cookies worden voornamelijk gebruikt voor het bijhouden van uw sessie en om uw
ervaring op onze website te verbeteren.</p>

<p>Verder zal onze website gebruik maken van tracking cookies die uw surfgedrag analyseert
zodat wij u kunnen informeren over activiteiten van Joris Ijs die u mogelijks interessant
vindt. Deze analyse zal gebeuren met behulp van Google Analytics. Deze verwerking van
uw persoonsgegevens zal louter plaatsvinden nadat u hiervoor uw toestemming hebt
gegeven. U bent steeds in de mogelijkheid om uw toestemming hierover in te trekken.
</p>

<p><h4>Welke cookies gebruiken we op https://www.jorisijs.be/?</h4></p>
<p>Joris Ijs maakt gebruik van verschillende soorten cookies. Meer informatie over de
eigenschappen van de verschillende cookies kan u vinden in volgende tabel:</p>

<table>
    <tr>
        <th>Persoonsgegevens </th>
        <th>Verwerkingsdoel</th>
        <th>Naam</th>
        <th>Type</th>
        <th>Rechtmatigheid </th>
        <th>Bewaartermijn </th>
    </tr>

    <tr>
        <td>Apparaat-, en
locatiegegeve
ns, unieke
code, lokale
opslag,
cookies</td>
        <td>Google
Analytics:
bijhouden van
statistieken
over de
website</td>
        <td>_ga
_gid
_gat</td>
        <td>Niet Functioneel</td>
        <td>Toestemming</td>
        <td>2 jaar
24 uur
Einde
sessie</td>
    </tr>

    <tr>
        <td>IP-adres </td>
        <td>Onthoudt uw
cookievoorkeuren</td>
        <td>Cookiesple
as
_status</td>
        <td>Functioneel</td>
        <td>Gerechtvaardigd
belang</td>
        <td>1 jaar</td>
    </tr>
</table>

</div>
        )
    }
}

export default PrivacyPolicyView